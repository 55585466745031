<template>
    <div v-show="show" class="ant-modal-root">
      <div class="ant-modal-mask"></div>
      <div class="ant-modal-wrap ant-modal-centered">
        <div class="ant-modal i-modal" :class="{'modal-nopadding': nopadding}" :style="{'width': width + 'px'}">
          <div class="ant-modal-content">
            <button @click="closeModal" type="button" class="ant-modal-close">
              <span class="ant-modal-close-x">
                <img width="24" src="../../assets/static/icon_close.9c11858e.svg"/>
              </span>
            </button>
            <div v-if="!!title" class="ant-modal-header">
              <div class="ant-modal-title">{{title}}</div>
            </div>
            <div class="ant-modal-body">
               <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: "customModal",
  props: {
    value: {
       type: Boolean,
       default: false
    },
    title: String,
    width: {
       type: Number,
       default: 368
    },
    nopadding: {
       type: Boolean,
       default: false
    }
  },
  data() {
    return {
       show: false
    }
  },
  methods: {
    closeModal(){
        this.show = false;
        this.$emit('input', false);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler:function(val){
        this.show = val;
      }
    }
  }
};
</script>
